

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh; 
}

main {
  flex-grow: 1;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    width: 100%;
    
  }

  .icon {
    width: 4em; 
    height: 4em; 
    margin: 2em;
    object-fit: contain; 
  }

.card-img {
    width: 100%;
    height: 40vh;
    object-fit: cover;
}
